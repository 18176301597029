<template>
<div class="">
    <div class="d-flex align-items-center justify-content-start">
        <div class="me-3">
            <img v-if="item.image" style="width:3rem;" :src="absoluteUrl(item.image)" />
        </div> 
        <div class="col-6"> 
            <div class="">Status</div>
            <span v-if="item.is_verified" class="badge bg-success font-sm"> Verified</span>
            <span v-else class="badge bg-warning font-sm">Pending</span>
        </div>
    </div>
    <div class="row pt-3 g-2">
        <div class="col-12"> 
            <span>Full name</span>
            <h6> {{item.last_name}}, {{item.first_name}} {{item.middle_name}} </h6>
        </div>
        <div class="col-sm-6"> 
            <span>Phone number</span>
            <h6> {{item.mobile}} </h6>
        </div> 
        <div v-if="item.lga" class="col-sm-6"> 
            <span>Local Government</span>
            <h6> {{item.lga.name}} </h6>
        </div>
        <div v-if="item.ward" class="col-sm-6"> 
            <span>Ward</span>
            <h6> {{item.ward.name}} </h6>
        </div>
        <div v-if="item.unit" class="col-sm-6"> 
            <span>Unit</span>
            <h6> {{item.unit.name}} </h6>
        </div>
        <div class="col-12"> 
            <span>Address</span>
            <h6> {{item.address}} </h6>
        </div>
        <div class="col-sm-6"> 
            <span>BVN</span>
            <h6> {{item.bvn}} </h6>
        </div>
        <div class="col-sm-6"> 
            <span>NIN</span>
            <h6> {{item.nin}} </h6>
        </div>
        <div class="col-sm-6"> 
            <span>VIN</span>
            <h6> {{item.vin}} </h6>
        </div>
        <div class="col-sm-6"> 
            <span>Account name</span>
            <h6> {{item.account_name}} </h6>
        </div>
        <div class="col-sm-6"> 
            <span>Account number</span>
            <h6> {{item.account_number}} </h6>
        </div>
        <div v-if="item.bank" class="col-sm-6"> 
            <span>Bank</span>
            <h6> {{item.bank.name}} </h6>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            required: true
        }
    },   
}
</script>

<style>

</style>