
<template>
<div>
  <div v-if="!isLoading" class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">My group</h5>
      <div class="row mb-4">
        <div class="col-12">
          <div class="card overflow-hidden">
            <div class="card-body">
              <div class="d-flex align-items-center justify-content-start mb-3">
                <div class="me-3">
                  <img v-if="group.logo" style="width:3rem;" :src="absoluteUrl(group.logo)" />
                </div> 
                <div class="">
                  <div class="friendly-id mb-1">
                    {{group.friendly_id}}
                  </div>
                  <div class="">
                    <strong class="me-1">Status:</strong>
                    <span v-html="statusBadge(group.status)"> </span>
                  </div>
                </div>
              </div>
              <h4 class="lh-base mb-0">{{group.name}}</h4>
              <p v-if="group.description" class="mb-0 mt-2 pt-1 text-muted">{{group.description}}</p>
              <div class="d-grid d-sm-flex gap-3 mt-4">
                <a class="btn btn-primary" target="_black" :href="absoluteUrl(`/groups/${group.friendly_id || '...'}/member`)">Add member</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-2 gy-2">
        <div class="col-12">
          <group-members />
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import GroupMembers from './GroupMembers.vue';

export default {
  name: "group-show",
  components:{
    IsLoading,
    GroupMembers
  },
  data() {
    return {
      isLoading: true,
      popupModalAddMember: false,
      group: null
    }
  },
  methods: {
    fetchGroup(){
      this.isLoading = true
      this.$http.get('/groups')
      .then((response) => {
        this.isLoading =  false
        if(response.data.success){
          this.group = response.data.data
        }else{
          this.$router.push({path: "/error-404"})
        }
      })
    },
    initializeAll(){
    }
  },
  created(){
    this.fetchGroup()
  },
}

</script>

